<section id="subheader" class="text-light" style="background: linear-gradient(104.46deg, #00CAFE -7.08%, #17025B 25.36%, #151269 70.81%, #00B8FF 105.66%);">
  <div class="center-y relative text-center">
    <div class="container">
      <div class="row">

        <div class="col-md-12 text-center">
          <h1 class="fs-explore" translate>Activity</h1>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</section>
<!-- section close -->
<div class="collectionMainContainer">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="tradeBlock">
          <div class="animated fadeIn">
            <div class="card">
              <div class="card-header" style="color: white!important; background-color: #001252;">
                <i class="cil-swap-vertical titleIcon"></i>
                {{'Activity'|translate}}
              </div>
              <div class="card-body historyBody">
                <table class="table table-bordered table-striped table-sm">
                  <thead>
                    <tr style="background-color: white!important;">
                      <th translate>Collection</th>
                      <th translate>Item</th>
                      <th translate>Event</th>
                      <th translate>Price</th>
                      <th translate>From</th>
                      <th translate>To</th>
                      <th translate>Transaction Hash</th>
                      <th translate>Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of histories" style="background-color: white!important;">
                      <td>
                        <div *ngIf="item.collection_id != null">
                          <div class="itemDetail" [routerLink]="'/collection/detail/' + item.collection_id._id"><img
                              [src]="mediaBase + '/images/collection/logo/' + item.collection_id.image"></div>
                        </div>
                      </td>
                      <td>
                        <div class="itemDetail" [routerLink]="'/item/view/' + item.item_id._id"><img
                            [src]="mediaBase + '/images/item/thumb/' + item.item_id.thumb"></div>
                      </td>
                      <td>{{item.history_type|translate}}</td>
                      <td>{{item.price}} {{item.set_token}}</td>
                      <td>
                        <div *ngIf="item.from_id != null"><a href="javascript:void(0)"
                            [routerLink]="'/profile/' + item.from_id._id">{{item.from_id.first_name}}
                            {{item.from_id.last_name}}</a></div>
                      </td>
                      <td>
                        <div *ngIf="item.to_id != null"><a href="javascript:void(0)"
                            [routerLink]="'/profile/' + item.to_id._id">{{item.to_id.first_name}}
                            {{item.to_id.last_name}}</a></div>
                      </td>
                      <td>
                        <div *ngIf="item.transaction_hash != ''"><a
                            [href]="blockChainExplorer + 'tx/'+item.transaction_hash"
                            target="_blank">{{item.transaction_hash}}</a>
<!--                          <span *ngIf="item?.data?.gasUsed">-->
<!--                            <br />Gas: {{ item.data.gasUsed}}-->
<!--                          </span>-->
                        </div>
                      </td>
                      <td>{{item.created_date | amDateFormat:'YYYY-MM-DD HH:mm:ss'}}</td>
                    </tr>
                    <tr *ngIf="histories.length==0 && historyLoading == false">
                      <td colspan="8">
                        <alert type="info" ng-reflect-type="info">
                          <div role="alert" class="alert alert-info" ng-reflect-ng-class="">
                            <strong>Oops!</strong> {{'No Data Found'|translate}}
                          </div>
                        </alert>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <nav>
                  <ul class="pagination" *ngIf="!historyLoading">
                    <li class="page-item" *ngIf="pageHistory!=1"><a class="page-link" href="javascript:void(0)"
                        (click)="prevHistoryPage()" translate>Prev</a></li>
                    <li class="page-item" *ngIf="hasNextHistoryPage"><a class="page-link" href="javascript:void(0)"
                        (click)="nextHistoryPage()" translate>Next</a></li>
                  </ul>
                  <ul class="pagination" *ngIf="historyLoading">
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" translate>Loading...</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
