import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialLoginModule, SocialAuthServiceConfig
} from 'angularx-social-login';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import {AuthInterceptor} from './services/auth-interceptor'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import { HomeComponent } from './components/home/home.component';
import { MarketplaceComponent } from './components/marketplace/marketplace.component';
import { StatsComponent } from './components/stats/stats.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { ResetComponent } from './components/reset/reset.component';
import { ProfileComponent } from './components/profile/profile.component';
import { config } from './constants/config';
import { FileUploadModule } from 'ng2-file-upload';
import { MomentModule } from 'ngx-moment';
import {
  AppAsideModule,
  AppSidebarModule,
} from '@coreui/angular';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LoaderModule } from './UI/loader/loader.module';
import { SettingsComponent } from './components/settings/settings.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ItemskeletonModule } from './UI/skeleton/itemskeleton/itemskeleton.module';
import { NodataModule } from './UI/nodata/nodata.module';
import { ItemModule } from './UI/item/item.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ErrorInterceptor } from './services/error-interceptor';
import { ReferRegisterComponent } from './components/refer-register/refer-register.component';
import { BannerComponent } from './components/banner/banner.component';
import { SectionTwoItemsComponent } from './components/home/section-two-items/section-two-items.component';
import { ItemsCarouselComponent } from './components/home/items-carousel/items-carousel.component';
import { TrendingModule } from './UI/trending/trending.module';
import { SubastasModule } from './UI/subastas/subastas.module';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MarketplaceComponent,
    StatsComponent,
    LoginComponent,
    RegisterComponent,
    ForgotComponent,
    ResetComponent,
    ProfileComponent,
    SettingsComponent,
    ReferRegisterComponent,
    BannerComponent,
    SectionTwoItemsComponent,
    ItemsCarouselComponent,
  ],
  imports: [
    BrowserModule,
    TrendingModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    IconModule,
    IconSetModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    SocialLoginModule,
    AppAsideModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    FileUploadModule,
    LoaderModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59
      }
    }),
    AlertModule.forRoot(),
    ItemskeletonModule,
    NodataModule,
    ItemModule,
    InfiniteScrollModule,
    SubastasModule
  ],
  providers: [
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { horizontalPosition: 'right', verticalPosition: 'top', duration: 6000}},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    IconSetService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              config.google_client_id
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(config.facebook_client_id)
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/', // or whatever path you're using
    '.json'
    );
}
