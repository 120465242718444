<div class="blog">

   <div>
    <div>
       <h4 style="color: #001252; padding: 2rem; font-weight: 900;"><span>{{title}}</span></h4> 
    </div>
    <div>
        <div class="circle">
            <div class="image lazy"  [ngStyle]="{'background-image': 'url(' + img + ')'}"></div>
        </div>
    </div>

  </div>
    <div style="position: absolute; bottom: -45px; margin: 32px;">
        <div class="left">
            <span class="cd-text1 font-weight-bold">{{name}}</span>
        </div>
        <div class="left">
            <span class="cd-text2">{{description}}</span>
        </div>
        <div class="left">
            <span class="cd-text3" style="cursor: pointer;"><i class="fa fa-arrow-right" style="font-size: 20px; color:#001252"></i></span>
        </div>
    </div>

</div>


