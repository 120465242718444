import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { CollectionService } from 'src/app/services/collection.service';
import { ItemService } from 'src/app/services/item.service';
import { config } from 'src/app/constants/config';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  mediaBase: string = config.media_path;
  currentUser: any;
  collectionMenu = '';
  collections: any = [];
  hotCollections: any = [];
  collectionItems: any = [];
  collectionItemsSubastas : any = [];
  trendingItems: any = [];
  trendingMysteryBox: any = [];
  loading = true;
  
  loadingMysteryBox = true;
  categories: any = [];
  bestSellers: any = [];
  sellMethod: any;
  tokenId: any;
  constructor(
    private userService: UserService,
    private router: Router,
    private collectionService: CollectionService,
    private itemService: ItemService
  ) {
    // this.currentUser = this.userService.getUser()
    this.userService.user.subscribe((user) => {
      this.currentUser = user;
    });
    this.loadTrendingItems();
    this.loadTrendingMysteryBox();
    this.getCollection();
    this.getCategory();
    this.getBestSellers();
   }

  ngOnInit(): void {
  }

  /**
   * This is the function which navigate uswer to login and marketplace on button click
   */
  openCreate = () => {
    if (this.currentUser == null) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/collection/mycollection']);
    }
  }

  /**
   * This is the function which load item by collection on tab click
   */
  getCollection = () => {
    const params = {
      page: 1,
      type: 'item',
    };
    this.collectionService.listCollection(params).subscribe(result => {
      console.log('l64: ', result);
      if (result.status === true) {
        this.hotCollections = [...result.tempArray];
        for (let index = 0; index < result.tempArray.length; index++) {
            const element = result.tempArray[index];
            this.collections.push(element);
            if (index === 6) {
              break;
            }
        }
        this.showItemByCollection(this.collectionMenu);
        this.showItemByCollectionSubastas(this.collectionMenu)
      }
    });
  }

  /**
   * This is the function which load item by collection on tab click
   */
  showItemByCollection = (id: any) => {
    this.loading = true;
    this.collectionMenu = id;
    let params = {};
    if (this.collectionMenu === '') {
      params = {
        page: 1,
        type: 'recent',
        // includemysteryboxes: true,
        keyword: ''
      };
    } else{
      params = {
        page: 1,
        type: 'collection',
        collection_id: this.collectionMenu,
        keyword: ''
      };
    }

    this.itemService.listItem(params).subscribe(result => {
      // console.log(result);
      if (result.status === true) {
        this.collectionItems = [];
        for (let index = 0; index < result.tempArray.length; index++) {
          const element = result.tempArray[index];
          this.tokenId = element.token_id
          this.sellMethod = element.sell_method;
          if(this.sellMethod == 1 || (this.sellMethod == 2 && this.tokenId == '')) {
            this.collectionItems.push(element);
            if (index === 7) {
              break;
            }
          }
         
        }
      }
      this.loading = false;
    });
  }

  showItemByCollectionSubastas = (id: any) => {
    this.loading = true;
    this.collectionMenu = id;
    let params = {};
    if (this.collectionMenu === '') {
      params = {
        page: 1,
        type: 'recent',
        // includemysteryboxes: true,
        keyword: ''
      };
    } else{
      params = {
        page: 1,
        type: 'collection',
        collection_id: this.collectionMenu,
        keyword: ''
      };
    }

    this.itemService.listItem(params).subscribe(result => {
      if (result.status === true) {
        this.collectionItemsSubastas = [];
        const currentTime = Date.now();
        for (let index = 0; index < result.tempArray.length; index++) {
          const element = result.tempArray[index];
          this.tokenId = element.token_id;
          this.sellMethod = element.sell_method;
        
          if (this.sellMethod == 2 && this.tokenId == '') {
            const expireTime = element.expire_at * 1000;
            if (expireTime <= currentTime) {
              // The item has expired, remove it from the array
              this.collectionItemsSubastas.splice(index, 1);
              continue; // Skip to the next iteration of the loop
            }
        
            console.log(new Date(expireTime).toISOString().slice(0, 19).replace('T', ' '));
            this.collectionItemsSubastas.push(element);
            if (this.collectionItemsSubastas.length === 3) {
              break;
            }
          }
        }
      }
      this.loading = false;
    });
  }


  loadTrendingItems = () => {
    this.loading = true;
    let params = {
      page: 1,
      type: 'trending',
      keyword: ''
    };
   
    this.itemService.listItem(params).subscribe(result => {
      
      if (result.status === true) {
        this.trendingItems = [];
        for (let index = 0; index < result.tempArray.length; index++) { 
          const element = result.tempArray[index];
          if (element.for_sale ) {
            this.trendingItems.push(element);
            if (index === 3) {
              break;
            }
          }
        }
      }
      this.loading = false;
    });
  }

  loadTrendingMysteryBox = () => {
    this.loadingMysteryBox = true;
    let params = {
      page: 1,
      type: 'mysterybox',
      keyword: ''
    };
   
    this.collectionService.listCollection(params).subscribe(result => {
      this.trendingMysteryBox = [];
      if (result.status === true) {
        for (let element of result.tempArray) {
          this.trendingMysteryBox.push({
            name: element.name,
            thumb: element.image,
            price: element.price,
            sell_method: 1,
            // currency: "ETH",
            for_sale: true,
            status: "active",
            like_count: 0,
            collection_id: {_id: element._id, author_id: element.author_id}
          });
        }
      }
      this.loadingMysteryBox = false;
    });
  }

  /**
   * This is the output function which used to navigate user to item detail page
   */
  itemEvent = (event: any) => {
    console.log('event:', event);
    if (event.type === 'view') {
      this.router.navigate(['/item/view/' + event.item._id]);
    }
  };

  /**
   * This is the function which used to get category list from api
   */
  getCategory = () => {
    this.itemService.categoryList().subscribe(result => {
      for (let index = 7; index < result.tempArray.length; index++) {
        const element = result.tempArray[index];
        this.categories.push(element);
        if (index === 7) {
          break;
        }
      }
    });
  };

  getBestSellers = () => {
    this.userService.bestSellers().subscribe(result => {
      const arreglo = result.data.slice(0, 8)
      this.bestSellers = arreglo
    });
  }

  author = (item: any) => {
    const profile_image = item.profile_image !== '' ? item.profile_image : 'nouser.jpg';
    return this.mediaBase + '/images/user/' + profile_image;
  }

  authorFullname = (item: any) => {
    return  `${item.first_name} ${item.last_name}`;
  }

  collectionsWithItems = () => this.collections.filter((e: any) => e.published_items > 0);

  itemsActivatedForSale = () => {
    return this.collectionItems.filter((e: any) => e.for_sale && e.trending === false);
  }
}
