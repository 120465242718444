<div class="appContainer2">
    <div class="nft-main">
<!-- 
        <section id="section-hero" aria-label="section" class="no-top no-bottom vh-100"
             style="background: linear-gradient(104.46deg, #00CAFE -7.08%, #17025B 25.36%, #151269 70.81%, #00B8FF 105.66%);">
                <div class="container">
                    <div class="gpt3__header section__padding wow fadeInUp animated changetextsize" data-wow-delay=".75s"
                style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white">
                    <div class="gpt3__header-content">
                        <h1 class="gradient__text">
                             The exclusive and limited 
                        </h1>
                    <h1 class="gradient__text">
                        Fan Club
                    </h1>
                    <a href="javascript:void(0)" routerLink="/marketplace"
                        class="newbtn btnsize3 wow fadeInUp lead animated" data-wow-delay="1.25s"
                            style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;"
                      translate>explore</a>
                    </div>
                        <div class="gpt3__header-image">
                             <img src="./../../../assets/images/home.png" alt="Home"/>
                        </div> 
                </div> 
                </div>
        </section> -->

        <section id="section-hero" aria-label="section" class="no-top no-bottom vh-100"
        style="background: linear-gradient(104.46deg, #00CAFE -7.08%, #17025B 25.36%, #151269 70.81%, #00B8FF 105.66%);">
            <div class="v-center" style="background-size: cover;">
                <div class="principal__container container gpt3__header " style="background-size: cover;">
                        <div class="gpt3__header-content col-md-6" style="background-size: cover;">
                            <div class="spacer-single" style="background-size: cover;"></div>
                            <div class="spacer-10" style="background-size: cover;"></div>
                            <h1 class="wow fadeInUp animated changetextsize principal__text" data-wow-delay=".75s"
                                style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                translate>The exclusive and limited </h1>
                            <h1 class="wow fadeInUp animated changetextsize principal__text" data-wow-delay=".75s"
                                style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                                translate>Fan Club</h1>
                            <div class="spacer-10" style="background-size: cover;"></div>
                            <a href="javascript:void(0)" routerLink="/marketplace"
                               class="newbtn btnsize3 wow fadeInUp lead animated" data-wow-delay="1.25s"
                               style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;"
                               translate>explore</a>
                            <div class="mb-sm-30" style="background-size: cover;"></div>
                        </div>
                        <!-- <div class=" gpt3__header-image col-md-6 text-center" style="background-size: cover; padding-top: 70px;">
                            <app-items-carousel class="gpt3__header-image" [itemsActivatedForSale]="itemsActivatedForSale()"></app-items-carousel>
                        </div> -->
                        <div class="gpt3__header-image">
                            <img src="./../../../assets/images/homemarket.png" alt="Home"/>
                       </div> 
                </div>
            </div>
        </section>



        <section id="section-intro" style="background-color: white;"
                 *ngIf="!loading && collectionItemsSubastas.length > 0">
            <div class="container" style="background-size: cover">
                <div class="row" style="background-size: cover;">
                    <div class="col-lg-12" style="background-size: cover; margin-bottom: 40px;">
                        <div class="d-flex row text-center align-items-center" style="background-size: cover; gap: 0px;">
                            <h2 translate class="style-2">Subastas</h2>
                            <svg width="108" height="6" viewBox="0 0 108 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 3H105" stroke="#00B8FF" stroke-width="5" stroke-linecap="round"/>
                            </svg>  
                        </div>
                        <div class="d-flex justify-content-center mb-4">
                        </div>  
                    </div>
                </div>
                <div class="row" style="background-size: cover;">
                    <div class="row wow fadeIn justify-content-center" >
                        <app-subastas [item]="item" (itemEvent)="itemEvent($event)" *ngFor="let item of collectionItemsSubastas" 
                                  class="d-item col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        </app-subastas>
                    </div>
                </div>
            </div>
        </section>
    

        <section class="nft-trending no-top no-bottom" *ngIf="trendingItems.length > 0" style="background-color: white;">
            <div class="nft-trending-head">
                <div class="container">
                    <div class="col-lg-12" style="background-size: cover;">
                        <div class="d-flex row text-center align-items-center mb-4" style="background-size: cover;">
                            <h2 translate class="style-2">Trending</h2>
                            <svg width="108" height="6" viewBox="0 0 108 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 3H105" stroke="#00B8FF" stroke-width="5" stroke-linecap="round"/>
                            </svg>  
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <div class="container">
                    <app-itemskeleton *ngIf="loading"></app-itemskeleton>
                    <div class="row wow fadeIn" *ngIf="!loading && itemsActivatedForSale().length>0">
                        <app-trending [item]="item" (itemEvent)="itemEvent($event)" *ngFor="let item of trendingItems"
                            class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        </app-trending>
                    </div>
                </div>
            </div>
        </section>


        <section id="section-intro" style="background-color: white;"
                 *ngIf="!loading && collectionItems.length>0">
            <div class="container" style="background-size: cover">
                <div class="row" style="background-size: cover;">
                    <div class="col-lg-12 col-md-12" style="background-size: cover;">
                        <div class="d-flex row text-center align-items-center mb-5-lg last__item">
                            <div class="d-flex justify-content-center" style="background-size: cover;">
                                <h2 translate class="style-2 font-weight-lighter">Last</h2>
                                <h2 translate class="style-2 ml-2">items</h2>
                            </div>
                            <svg width="108" height="6" viewBox="0 0 108 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 3H105" stroke="#00B8FF" stroke-width="5" stroke-linecap="round"/>
                            </svg>  
                        </div>
                    </div>
                </div>
                <div class="row" style="background-size: cover;">
                    <div class="row wow fadeIn" >
                        <app-item [item]="item" (itemEvent)="itemEvent($event)" *ngFor="let item of itemsActivatedForSale()" 
                                  class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        </app-item>
                    </div>
                </div>
            </div>
        </section>

        <section class="no-top no-bottom" style="background-color: white;">
            <div class="container">
                <div class="row" *ngIf="bestSellers.length > 0">
                    <div class ="d-flex row text-center align-items-center mb-4">
                        <div class="d-flex justify-content-center" style="background-size: cover;">
                            <h2 translate class="style-2 font-weight-lighter" translate>Top</h2>
                            <h2 translate class="style-2 ml-2" translate>Sellers</h2>
                        </div>
                        <svg width="108" height="6" viewBox="0 0 108 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 3H105" stroke="#00B8FF" stroke-width="5" stroke-linecap="round"/>
                        </svg>  
                    </div>
                    <div class="col-md-12 wow fadeIn">
                        <ol class="gridResponsive" style=" display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-template-rows: 2fr 100px;
                        grid-column-gap: 20px;
                        grid-row-gap: 40px;
                        justify-items: stretch;
                        align-items: stretch; padding-left: 0!important;" *ngIf="bestSellers">
                            <li  *ngFor="let item of bestSellers">
                                <div class="author_list_pp1"> 
                                    <a href="javascript:void" [routerLink]="'/profile/'+item._id+'/created'">
                                        <img class="lazy" [src]="author(item)" alt="{{authorFullname(item)}}">
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="author_list_info align-items-center" style="background: #001252;
                                border-radius: 60px;
                                 height: 4rem;     padding-top: 1rem;">
                                    <a href="javascript:void"
                                    class="author_text"
                                    [routerLink]="'/profile/'+item._id+'/created'">{{authorFullname(item)}}</a>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </section> 


        <section style="background-color: white">
            <div class="container">
                <div class= "d-flex row text-center align-items-center" style="margin-bottom: 5rem;">
                    <div class="d-flex justify-content-center" style="background-size: cover;">
                        <h2 translate class="style-2 font-weight-lighter">Create your</h2>
                        <h2 translate class="style-2 ml-2">NFT</h2>
                    </div>
                    <svg width="108" height="6" viewBox="0 0 108 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 3H105" stroke="#00B8FF" stroke-width="5" stroke-linecap="round"/>
                    </svg>  
                </div>
            </div>
          <div class="container">
            <div class="row" style="background-size: cover;">
                <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-xs-12" style="background-size: cover; margin-bottom: 4rem;" >
                    <app-section-two-items [title]="'STEP 01'" [img]="'../../../assets/images/paso1.png'" [name]="'Connect Your wallet'" [description]="'Connect your wallet to the marketplace to register your account.'"  ></app-section-two-items>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-xs-12" style="background-size: cover; margin-bottom: 4rem;">
                    <app-section-two-items [title]="'STEP 02'" [img]="'../../../assets/images/paso2.png'" [name]="'Create your collection'" [description]="'Create your collection, name, royalties, select the sale token and publish.'" ></app-section-two-items>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-xs-12" style="background-size: cover; margin-bottom: 4rem;">
                    <app-section-two-items [title]="'STEP 03'" [img]="'../../../assets/images/paso3.png'" [name]="'Add your NFTs'" [description]="'Add the NFTs to your collection, you can add properties and different prices to your NFTs.'" ></app-section-two-items>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-xs-12" style="background-size: cover; margin-bottom: 4rem;">
                    <app-section-two-items [title]="'STEP 04'" [img]="'../../../assets/images/paso4.png'" [name]="'Sell your NFTs'" [description]="'Publish and sell your NFTs, you will receive royalties in the future while your NFTs are traded.'" ></app-section-two-items>
                </div>
        </div>
          </div>
        </section>

    </div>
</div>



