import { Injectable } from '@angular/core';
import Onboard from 'bnc-onboard';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import Web3 from 'web3';
import { UserService } from './user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
declare var window: any;
// import detectEthereumProvider from '@metamask/detect-provider';

@Injectable({
  providedIn: 'root'
})
export class OnboardService {
  gasLimit = 200000;
  private account: any = null;
  // private readonly web3: any;
  private enable: any;
  public RPC_URL = 'https://mainnet.infura.io/v3/db8a3167985044a094fc54130952d22f'
  public APP_NAME = 'Marketplace'
  public INFURA_KEY = 'db8a3167985044a094fc54130952d22f'
  private onboard: any;
  private web3: any;
  public networkId = 80001; // 80001:Testnet
  private networkName = 'Polygon Testnet';

  private balance: BehaviorSubject<number>;
  public currentBalance: Observable<number>;
  public walletAccount = new Subject<string>();

  private address: BehaviorSubject<string>;
  public currentAddress: Observable<string>;

  constructor(private userService: UserService, private snackBar: MatSnackBar, ) {
    this.initialize();
    this.balance = new BehaviorSubject(0);
    this.currentBalance = this.balance.asObservable();

    this.address = new BehaviorSubject('');
    this.currentAddress = this.address.asObservable();
  }

  async checkAccount(): Promise<boolean> {
    if (!window.ethereum?.selectedAddress) {
        const walletSelected = await this.walletSelect();
        // let currentState = this.currentState();
        const readyToTransact = await this.walletCheck();
        // console.log('walletSelected:', walletSelected);
        if (walletSelected && readyToTransact) {
          return true;
        }
        this.snackBar.open('You need to select a wallet', '');
        return false;
    }
    return true;
  }

  async loadWeb3(): Promise<void> {
    // if (window.ethereum === undefined) {
    //   this.snackBar.open('Non-Ethereum browser detected. Install MetaMask', '', { duration: 2000 });
    //   // alert('Non-Ethereum browser detected. Install MetaMask');
    // } else {
    //   if (typeof window.web3 !== 'undefined') {
    //     // this.web3 = window.web3.currentProvider;
    //     this.web3 = new Web3(window.web3).currentProvider;
    //   } else {
    //     this.web3 = new Web3(window.ethereum);
    //     // this.web3 = new Web3.providers.HttpProvider('http://localhost:8545');
    //   }
    //   console.log('transfer.service :: constructor :: window.ethereum');
    //   // window.web3 = new Web3(window.ethereum);
    //   window.web3 = this.web3;
    //   console.log('transfer.service :: constructor :: this.web3');
    //   console.log(this.web3);
    //   this.enable = this.enableMetaMaskAccount();
    // }

    if (window.ethereum === undefined) {
      this.snackBar.open(
        'Non-Ethereum browser detected. Install MetaMask',
        '',
        { duration: 2000 }
      );
      return;
    }

    if (this.web3) {
      return this.web3;
    }
    if (window.ethereum) {
      this.web3 = new Web3(window.ethereum);
      // await window.ethereum.eth_requestAccounts;
      // we can use metamask
    } else if (window.web3) {
      this.web3 = new Web3(window.web3).currentProvider;
    }
  }
  async initialize(): Promise<void> {
    // const tokenBalanceCheck = this.tokenBalance('0x376495a6878a0e2b142c6bf5c0d29fee785df5c9', 'NRT', 5)
    const defaultWalletChecks = [
      { checkName: 'accounts' },
      { checkName: 'connect' },
      { checkName: 'network' }
    ];
    const initializationOptions = {
      // networkId: 56,
      // networkName: 'Binance Mainnet',
      dappId: '3884fd22-38d5-4ca7-8a32-3fe45b307c30',
      networkId: this.networkId,
      networkName: this.networkName,
      blockPollingInterval: 5000,
      darkMode: true,
      subscriptions: {
        wallet: (wallet: any) => {
          this.web3 = new Web3(wallet.provider);
          // console.log('wallet:', wallet);
        },
        address: (address: string) => {
            console.log(`address: ${address}`);
            // if (address) {
            //     this.userService.updateMetamaskAddress({metamask_address: address}).subscribe(result => {
            //       console.log(result);
            //     });
            // }
        },
        network: (networkId: number) => {
          console.log(`networkId: ${networkId}`);
        },
        balance: (balance: string) => {
          const calcBalance = parseInt(balance) / 1e18;
          this.balance.next(calcBalance);
          // console.log(`balance: ${calcBalance}`);
        },
      },
      walletCheck: [
        ...defaultWalletChecks,
        // tokenBalanceCheck
      ],
      //   [
      //   { checkName: 'accounts' },
      //   { checkName: 'connect' },
      //   { checkName: 'network' },
      //   // { checkName: 'balance', minimumBalance: '1000000' }
      // ],
      walletSelect: {
        heading: "Select a Wallet",
        description: "Please select a wallet that you would like to use with this Dapp",
        wallets: [
          { 
            walletName: "metamask", 
            preferred: true 
          },
          // {
          //   walletName: "walletConnect",
          //   infuraKey: this.INFURA_KEY, 
          //   preferred: true  
          // },
          // { 
          //   walletName: "walletLink", 
          //   rpcUrl: this.RPC_URL, 
          //   appName: this.APP_NAME, 
          //   preferred: true   },
          // { 
          //   walletName: "binance" , 
          //   preferred: true  },
      ]
      },


    };
    this.onboard = Onboard(initializationOptions);
    // this.loadWeb3();
  }

  enableMetaMaskAccount = async (): Promise<any> => {
    let enable = false;
    if (!window.ethereum) {
      return false;
    }
    await new Promise((resolve, reject) => {
      enable = window.ethereum.enable();
    });
    return Promise.resolve(enable);
  };

  getOnboard = () => this.onboard;
  walletSelect = () => this.onboard.walletSelect();
  walletCheck =  () => this.onboard.walletCheck();
  currentState = () => this.onboard.getState();

  deployContract = async (contractData: any) => {
    this.loadWeb3();

    const accounts = await this.web3.eth.getAccounts();
    // console.log('Attempting to deploy from account', accounts[0]);
    const result = await new this.web3.eth.Contract(contractData.abi)
      .deploy({ data: contractData.bytecode })
      .send({ gas: this.gasLimit, from: accounts[0] });

    //   .on('receipt', function () {
    //   //
    // });

    // console.log('Contract deployed to', result.options.address);
    // console.log('Deploy result', result);
    return { ...result, address: accounts[0] };
  };

  // function for convert numbers to hexadecimal string
  convertToHex = (number: any) => {
    return '0x' + number.toString(16);
  };

  setApprovalForAll = async (contractData: any, operator: string) => {
    this.loadWeb3();
    const accounts = await this.web3.eth.getAccounts();

    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    ); // Replace your own config
    return await ERC721.methods
      .setApprovalForAll(operator, true)
      .send({ gas: '2000000', from: accounts[0] });
  };

  disconnectWallet = () => {
    // this.onboard.dis
  };

  getAdmin = async (contractData: any) => {
    this.loadWeb3();
    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    );
    return ERC721.methods.owner().call();
  };

  mintToken = async (contractData: any) => {
    this.loadWeb3();

    console.log('contractData:', contractData);

    const accounts = await this.web3.eth.getAccounts();
    console.log('accounts:', accounts);

    if (accounts.length === 0) {
      return false;
    }

    const params = [
      accounts[0],
      contractData.tokenName,
      this.convertToHex(parseFloat(contractData.tokenPrice) * 1e18),
      String(contractData.tokenSellMethod),
      String(contractData.tokenExpireAt),
      String((parseFloat(contractData.tokenRoyalty) / 100) * 1e6),
      true,
      contractData.collection_id,
    ];
    console.log('params:', params);

    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    ); // Replace your own config
    console.log(ERC721);
    const tx = ERC721.methods.mint(...params);
    const gas = await tx.estimateGas({ from: accounts[0] });

    const nonce = await this.web3.eth.getTransactionCount(accounts[0]);
    return ERC721.methods.mint(...params).send({
      value: String(0x0),
      gas,
      from: accounts[0],
      nonce,
    });
  };

  // mintTokens = async (contractData: any) => {
  //   this.loadWeb3();

  //   console.log('contractData:', contractData);

  //   const accounts = await this.web3.eth.getAccounts();
  //   console.log('accounts:', accounts);

  //   if (accounts.length === 0) {
  //     return false;
  //   }

  //   const names: any = [];
  //   const prices: any = [];
  //   for (let i=0; i< contractData.tokenPrice.length; i++) {
  //     names.push(contractData.tokenName[i]);
  //     prices.push(
  //       this.convertToHex(parseFloat(contractData.tokenPrice[i]) * 1e18)
  //     );
  //   }

  //   const params = [
  //     accounts[0],
  //     names,
  //     prices,
  //     String(1),
  //     String(0),
  //     String((parseFloat(contractData.tokenRoyalty) / 100) * 1e6),
  //     true,
  //     contractData.collection_id,
  //   ];
  //   console.log('params:', params);

  //   const ERC721 = new this.web3.eth.Contract(
  //     contractData.abi,
  //     contractData.address
  //   ); // Replace your own config
  //   console.log(ERC721);
  //   const tx = ERC721.methods.mintBatch(...params);
  //   const gas = await tx.estimateGas({ from: accounts[0] });

  //   const nonce = await this.web3.eth.getTransactionCount(accounts[0]);
  //   return ERC721.methods.mintBatch(...params).send({
  //     value: String(0x0),
  //     gas,
  //     from: accounts[0],
  //     nonce,
  //   });
  // };

  bid = async (contractData: any) => {
    this.loadWeb3();
    // console.log('contractData:', contractData);

    const accounts = await this.web3.eth.getAccounts();
    // console.log('accounts:', accounts);

    if (accounts.length === 0) {
      return false;
    }

    const params = [
      String(contractData.tokenId),
      this.convertToHex(parseFloat(contractData.tokenAmount) * 1e18),
    ];
    // console.log('params:', params);
    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    ); // Replace your own config


    return ERC721.methods.bid(...params).send({
      from: accounts[0],
      to: contractData.address,
      value: this.convertToHex(parseFloat(contractData.tokenAmount) * 1e18),
    });
  };

  resolveAuction = async (contractData: any) => {
    this.loadWeb3();
    console.log('contractData:', contractData);

    const accounts = await this.web3.eth.getAccounts();
    console.log('accounts:', accounts);

    if (accounts.length === 0) {
      return false;
    }

    const params = [String(contractData.tokenId)];
    console.log('params:', params);
    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    ); // Replace your own config
    console.log(ERC721);
    const tx = ERC721.methods.resolveAuction(...params);
    const gas = await tx.estimateGas({ from: accounts[0] });
    const nonce = await this.web3.eth.getTransactionCount(accounts[0]);

    return tx.send({
      value: String(0x0),
      gas,
      from: accounts[0],
      nonce,
    });
  };

  minAmountForBid = async (contractData: any) => {
    this.loadWeb3();
    // console.log('contractData:', contractData);

    const accounts = await this.web3.eth.getAccounts();
    // console.log('accounts:', accounts);

    if (accounts.length === 0) {
      return false;
    }
    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    );
    // console.log(ERC721);
    return ERC721.methods.minAmountForBid(contractData.tokenId).call();
  };

  metaToken = async (contractData: any) => {
    this.loadWeb3();
    console.log('contractData:', contractData);

    const accounts = await this.web3.eth.getAccounts();
    console.log('accounts:', accounts);

    if (accounts.length === 0) {
      return false;
    }
    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    );
    return ERC721.methods.tokenMeta(`${contractData.tokenId}`).call();
  };

  balanceOf = async (contractData: any) => {
    this.loadWeb3();
    const accounts = await this.web3.eth.getAccounts();
    // console.log('accounts:', accounts);
    if (accounts.length === 0) {
      return 0;
    }

    const balance_ether = await this.web3.eth.getBalance(accounts[0]);
    return balance_ether>0 ? balance_ether/1e18 : 0;
  };

  approveMarket = async (contractData: any) => {
    this.loadWeb3();
    const accounts = await this.web3.eth.getAccounts();
    const decimal = this.metaTokenContract(contractData)
    // console.log('accounts:', accounts);
    if (accounts.length === 0) {
      return false;
    }

    const params = [
      contractData.erc721Address,
      this.convertToHex(contractData.tokenPrice * 1e18),
    ];

    // console.log('params:', params);

    
    const ERC720 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    ); // Replace your own config
    const tx = ERC720.methods.approve(...params);
    const gas = await tx.estimateGas({ from: accounts[0] });
    const nonce = await this.web3.eth.getTransactionCount(accounts[0]);
    return tx.send({
      value: String(0x0),
      gas,
      from: accounts[0],
      to: contractData.address,
      nonce,
    });
  };

  buyToken = async (contractData: any): Promise<false | any> => {
    this.loadWeb3();

    const accounts = await this.web3.eth.getAccounts();
    console.log('accounts:', accounts);
    if (accounts.length === 0) {
      return false;
    }
    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    ); // Replace your own config
    const params = [
      String(contractData.tokenId),
      String(0),
      String(0),
      String(contractData.tokenOwner),
      String(contractData.tokenName),
      this.convertToHex(contractData.tokenPrice * 1e18),
      String((parseFloat(contractData.tokenRoyalty) / 100) * 1e6),
      String(contractData.tokenCollectionId),
    ];

    console.log('params:', params);
 
    return ERC721.methods.buy(...params).send({
      from: accounts[0],
      to: contractData.address,
      value: this.convertToHex(contractData.tokenPrice * 1e18),
    });
 /*.then((result: any) => {
      console.log('purchaseToken: ', result);

      return result;
    }).catch((error: Error) => {
      console.log('purchaseToken error:', error);
      return false;
    });*/
  };

  buyTokenWithCoupon = async (contractData: any): Promise<false | any> => {
    this.loadWeb3();

    const accounts = await this.web3.eth.getAccounts();
    console.log('accounts:', accounts);
    if (accounts.length === 0) {
      return false;
    }
    /**
     * @todo check how to use safeTransferFrom
     */
    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    ); // Replace your own config
    console.log(ERC721);

    const params = [
      String(contractData.tokenId),
      String(1),
      contractData.couponCode,
      String(contractData.tokenOwner),
      String(contractData.tokenName),
      this.convertToHex(contractData.tokenPrice * 1e18),
      String((parseFloat(contractData.tokenRoyalty) / 100) * 1e6),
      String(contractData.tokenCollectionId),
    ];

    console.log('params:', params);

    // const ERC720 = new this.web3.eth.Contract(contractData.abi, contractData.address); // Replace your own config
    const tx = ERC721.methods.buy(...params);
    const gas = await tx.estimateGas({ from: accounts[0] });
    const nonce = await this.web3.eth.getTransactionCount(accounts[0]);
    return tx.send({
      value: String(0x0),
      gas,
      from: accounts[0],
      to: contractData.tokenOwner,
      nonce,
    });
  };

  setTokenSale = async (contractData: any): Promise<false | any> => {
    // if (!this.checkAccount()) { return false; }
    this.loadWeb3();
    // if (!this.enable) {
    //   this.snackBar.open('You need to select a wallet', '', { duration: 2000 });
    //   return false;
    // }

    const accounts = await this.web3.eth.getAccounts();
    console.log('accounts:', accounts);

    if (accounts.length === 0) {
      return false;
    }
    /**
     * @todo check how to use safeTransferFrom
     */

    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    ); // Replace your own config
    console.log(ERC721);
    // const price = String(contractData.tokenPrice * 1e18);

    const params = [
      contractData.tokenId,
      contractData.tokenForSale,
      contractData.tokenSellMethod,
      contractData.tokenExpireAt,
      this.convertToHex(contractData.tokenPrice * 1e18),
    ];

    console.log('params:', params);

    const tx = ERC721.methods.setTokenSale(...params);
    const gas = await tx.estimateGas({ from: accounts[0] });
    const nonce = await this.web3.eth.getTransactionCount(accounts[0]);
    return tx.send({
      value: String(0x0),
      gas,
      from: accounts[0],
      nonce,
    });
    // .then((result: any) => {
    //     console.log('setTokenSale: ', result);
    //     return result;
    //   }).catch((error: Error) => {
    //     console.log('setTokenSale error:', error);
    //     return false;
    //   });
  };

  signChallenge = async (data: [any]) => {
    this.loadWeb3();
    const challenge = [
      {
        type: 'string',
        name: 'challenge',
        value: data,
      },
    ];

    const accounts = await this.web3.eth.getAccounts();
    console.log('accounts:', accounts);
    

    if (accounts.length === 0) {
      return false;
    }
    const from = accounts[0];
    console.log(from);
    this.walletAccount.next(from);
    
    const params = [data, from];
    const method = 'eth_signTypedData';

    return new Promise((resolve, reject) => {
      this.web3.currentProvider.sendAsync(
        {
          method,
          params,
          from,
        },
        async (err: any, result: any) => {
          if (err) {
            console.error(err);
            return reject(err);
          }
          if (result.error) {
            console.error(result.error);
            return reject(result.error);
          }
          return resolve(result.result);
        }
      );
    });
  };

  showErrorMessage = (error: String) => {
    console.log(error);
    const arrayE = error.split(',');
    let message = '';
    for (let i = 0; i < arrayE.length; i++) {
      if (arrayE[i].substr(4, 7) == 'message') {
        message = arrayE[i];
      }
    }
    return message;
  };

  metaTokenContract = async (contractDataToken: any) => {
    this.loadWeb3();
    console.log('contractDataToken:', contractDataToken);

    const accounts = await this.web3.eth.getAccounts();
    // console.log('accounts:', accounts);

    if (accounts.length === 0) {
      return false;
    }
    const ERC721 = new this.web3.eth.Contract(
      contractDataToken.abi,
      contractDataToken.address
    );

    return await ERC721.methods.decimals().call()

    
  };
  mintTokenContractOtherTokens = async (contractData: any, contractDataToken: any) => {
    console.log("procesando mint");
    this.loadWeb3();
    console.log(contractData)
    const accounts = await this.web3.eth.getAccounts();

    if (accounts.length === 0) {
      return false;
    }
    const decimal = await this.metaTokenContract(contractDataToken)
    console.log(decimal, 'Decimals of others tokens')

    const params = [
      accounts[0],
      contractData.tokenName,
      this.convertToHex(parseFloat(contractData.tokenPrice) * Math.pow(10, decimal)),
      String(contractData.tokenSellMethod),
      String(contractData.tokenExpireAt),
      String((parseFloat(contractData.tokenRoyalty) / 100) * 1e6),
      true,
      contractData.collection_id,
    ];

    console.log('params:', params);

    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address,
    ); // Replace your own config
    // console.log(ERC721);
    console.log(ERC721)
    const tx = await ERC721.methods.mint(...params);
    const gas = await tx.estimateGas({ from: accounts[0] });

    const nonce = await this.web3.eth.getTransactionCount(accounts[0]);
    return ERC721.methods.mint(...params).send({
      value: String(0x0),
      gas,
      from: accounts[0],
      nonce,
    });
  };

  setCollectionToken = async (contractData: any): Promise<false | any> => { 
    console.log('Firmar token test')
    this.loadWeb3();

    const accounts = await this.web3.eth.getAccounts();
    // console.log('accounts:', accounts);
    if (accounts.length === 0) {
      return false;
    }
    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    ); 

    const params = [
      String(contractData.tokenCollectionId),
      String(contractData.acceptedTokenIndex),
    ];
    console.log('params:', params);

    return ERC721.methods.setCollectionToken(...params).send({
      from: accounts[0],

    });

  }
  approveMarketWithTokens = async (contractData: any) => {
    this.loadWeb3();
    const accounts = await this.web3.eth.getAccounts();
    const decimal = await this.metaTokenContract(contractData)
    console.log(decimal, 'Decimals of others tokens')
    if (accounts.length === 0) {
      return false;
    }

    const params = [
      contractData.erc721Address,
      contractData.tokenPrice,
    ];

    // console.log('params:', params);

    
    const ERC720 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    ); // Replace your own config
    const tx = ERC720.methods.approve(...params);
    const gas = await tx.estimateGas({ from: accounts[0] });
    const nonce = await this.web3.eth.getTransactionCount(accounts[0]);
    return tx.send({
      value: String(0x0),
      gas,
      from: accounts[0],
      to: contractData.address,
      nonce,
    });
  };

  balanceOfWithTokens  = async (contractData: any) => {
    this.loadWeb3();
    const accounts = await this.web3.eth.getAccounts();
    const decimal = await this.metaTokenContract(contractData)
    console.log(decimal, 'Decimals of others tokens')
    if (accounts.length === 0) {
      return 0;
    }
    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    );
    console.log(this.address, 'aaaaaa')

    const balance_ether = await ERC721.methods.balanceOf(accounts[0]).call()
    console.log(balance_ether>0 ? balance_ether/10**decimal : 0)
    return balance_ether>0 ? balance_ether/10**decimal : 0;
  };

  buyTokenWithTokens = async (contractData: any, contractDataToken: any): Promise<false | any> => {
    this.loadWeb3();

    const accounts = await this.web3.eth.getAccounts();
    console.log('accounts:', accounts);

    const decimal = await this.metaTokenContract(contractDataToken)
    console.log(decimal, 'Decimals of others tokens')

    if (accounts.length === 0) {
      return false;
    }
    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    ); // Replace your own config
    const params = [
      String(contractData.tokenId),
      String(0),
      String(0),
      String(contractData.tokenOwner),
      String(contractData.tokenName),
      this.convertToHex(contractData.tokenPrice * 10 ** decimal),
      String((parseFloat(contractData.tokenRoyalty) / 100) * 1e6),
      String(contractData.tokenCollectionId),
    ];

    console.log('params:', params);
    const tx = ERC721.methods.buy(...params);
    const gas = await tx.estimateGas({ from: accounts[0] });
    const nonce = await this.web3.eth.getTransactionCount(accounts[0]);
    return tx.send({
      // value: String(0),
      gas,
      from: accounts[0],
      // to: contractData.address,
      nonce,
    }); /*.then((result: any) => {
      console.log('purchaseToken: ', result);

      return result;
    }).catch((error: Error) => {
      console.log('purchaseToken error:', error);
      return false;
    });*/
  };

  bidToken = async (contractData: any,erc20ContractData:any) => {
    this.loadWeb3();
    // console.log('contractData:', contractData);
    const decimal = await this.metaTokenContract(erc20ContractData)

    const accounts = await this.web3.eth.getAccounts();
    // console.log('accounts:', accounts);

    if (accounts.length === 0) {
      return false;
    }

    const params = [
      String(contractData.tokenId),
      this.convertToHex(parseFloat(contractData.tokenAmount) * 10 ** decimal),
    ];
    console.log('params:', params);
    const ERC721 = new this.web3.eth.Contract(
      contractData.abi,
      contractData.address
    ); // Replace your own config

    
    const bid =  ERC721.methods.bid(...params).send({
      from: accounts[0],
      to: contractData.address,
      value: this.convertToHex(parseFloat(contractData.tokenAmount) * 10 ** decimal),
    });

    console.log('bid:', params);
    return bid
  };

  allowanceToken = async (contractDataAllowance : any): Promise<false | any> => { 
    console.log('Firmar token test')
    this.loadWeb3();

    const accounts = await this.web3.eth.getAccounts();
    // console.log('accounts:', accounts);
    if (accounts.length === 0) {
      return false;
    }
    const ERC721 = new this.web3.eth.Contract(
      contractDataAllowance .abi,
      contractDataAllowance .addressToken
    ); 

    const params = [
      String(contractDataAllowance .addressOwner),
      String(contractDataAllowance .addressMarket),
    ];
    console.log('params:', params);

    const allowance = await ERC721.methods.allowance(...params).call({
      from: accounts[0],
    });
    console.log(allowance, 'allowance')
    return allowance
  }

  supplyToken = async (contractDataToken: any) => {
    this.loadWeb3();

    const accounts = await this.web3.eth.getAccounts();
    console.log('accounts:', accounts);

    if (accounts.length === 0) {
      return false;
    }
    const ERC721 = new this.web3.eth.Contract(
      contractDataToken.abi,
      contractDataToken.address
    );
    const supply = ERC721.methods.totalSupply().call();
    console.log(supply,'supply')
    return supply
  };

}


