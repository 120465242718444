<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card-group">
            <div class="card">
              <div class="card-body p-3">
                <form [formGroup]="forgot" (ngSubmit)="onSubmit(forgot)">
                  <h1 translate>Forgot Password?</h1>
                  <p class="text-muted"></p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-envelope"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Enter Email" formControlName="email"
                      autocomplete="username" required>
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-main color-2" translate>{{button_title}}</button>
                    </div>
                    <div class="col-6 text-right">
                      <button type="button" class="btn px-0 link-cs" routerLink="/login" translate>Go to
                        Login?</button>
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>