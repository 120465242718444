import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { config } from 'src/app/constants/config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserService } from './user.service';
declare var window: any;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private snackBar: MatSnackBar, private router: Router, private userService: UserService,) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        map((response: any) => {
          if (response?.body?.status == false && response?.body?.message == "Token is not valid") {
            this.snackBar.open("Token expired", "", { duration: 2000 });
            localStorage.removeItem("token");
            // location.href = config.base_url;
            this.userService.refreshUser();
            this.router.navigate(['/']);
            window.location.reload();
          }
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            console.log('this is client side error');
            errorMsg = `Error: ${error.error.message}`;
          }
          else {
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          }
          console.log(errorMsg);
          return throwError(errorMsg);
        })
      )
  }

  // intercept(req: HttpRequest<any>, next: HttpHandler) {

  //   const authToken = this.user.getToken();

  //   if(authToken != '') {
  //     const authReq = req.clone({
  //       headers: req.headers.set('Authorization', authToken)
  //     }); 
  //     return next.handle(authReq);
  //   } else {
  //     const authReq = req.clone(); 
  //     return next.handle(authReq);
  //   }
    
  // }
}