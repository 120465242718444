import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../services/user.service'
import { Router } from '@angular/router';
import { config } from 'src/app/constants/config';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { TranslateService } from '@ngx-translate/core';
declare var window: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  button_title:string = "Login"
  login: FormGroup;
  isAPILoading: boolean = false;
  allowSignUp: boolean = !config.is_demo;
  constructor(
    public formbuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private router: Router,
    private socialService: SocialAuthService,
    private translateService: TranslateService,
  ) { 
    this.login = formbuilder.group({
      'username': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(255)])],
      'password': [null, Validators.compose([Validators.required, Validators.minLength(3)])]
    });
  }

  ngOnInit(): void {
    
  }

  /**
   * This is the function which used to validate login form on submit
   */
  onSubmit(formData: any) {
    if (this.login.valid && this.button_title == "Login") {
      this.button_title = this.translateService.instant("Logging In...");
      this.loginApi({
        username: formData.value.username,
        password: formData.value.password
      });
    } else {
      if (this.login.controls.username.invalid) {
        if (this.login.controls.username.errors) {
          if (this.login.controls.username.errors.required) {
            this.snackBar.open(this.translateService.instant('Username is required'), "", { duration: 2000 });
          } else if (this.login.controls.username.errors.minlength) {
            this.snackBar.open(this.translateService.instant('Username required minimum 3 characters'), "", { duration: 2000 });
          } else if (this.login.controls.username.errors.maxlength) {
            this.snackBar.open(this.translateService.instant('Username required maximum 255 characters'), "", { duration: 2000 });
          } 
        }
      }else if (this.login.controls.password.invalid) {
        if (this.login.controls.password.errors) {
          if (this.login.controls.password.errors.required) {
            this.snackBar.open(this.translateService.instant('Password is required'), "", { duration: 2000 });
          } else if (this.login.controls.password.errors.minlength) {
            this.snackBar.open(this.translateService.instant('Password required minimum 3 characters'), "", { duration: 2000 });
          } 
        }
      }
    }
  }

  /**
   * This is the function which used to send login information with params to api
   */
  loginApi = async (params: any) => {
    this.isAPILoading = true;
     this.userService.loginApi(params).subscribe(result=>{
       this.isAPILoading = false;
       if(result.status == true) {
        localStorage.setItem('token',result.return_id);
        this.userService.refreshUser();
        this.snackBar.open(this.translateService.instant('Login Successful'), "", { duration: 2000 });
        // location.href = config.base_url;
        this.router.navigate(['/']);
       } else {
        this.button_title = "Login"
        this.snackBar.open(result.message, "", { duration: 2000 });
       }
     })
  }

  /**
   * This is the function which used to access google sign on button click
   */
  signInWithGoogle(): void {
  this.socialService.signIn(GoogleLoginProvider.PROVIDER_ID);
  this.socialService.authState.subscribe((user:any) => {
    
    this.isAPILoading = true;
    var params = {
        'username':user.name,
        'first_name': user.firstName,
        'last_name': user.lastName,
        'email':user.email,
        'photo':user.photoUrl,
        'profile_image':user.id + '.jpg',
        'social_info':JSON.stringify({"id":user.id,"type":"google"})
      }
      this.userService.register(params).subscribe(
        (result: any) => {
          this.isAPILoading = false;
          if(result.status == true) {
            localStorage.setItem('token',result.return_id);
            this.userService.refreshUser();
            this.snackBar.open(this.translateService.instant('Login Successful'), "", { duration: 2000 });
            // location.href = config.base_url;
            this.router.navigate(['/']);
          } else {
            this.snackBar.open(result.message, "", { duration: 2000 });
          }
        }
      )
  });
}
  
/**
* This is the function which used to access facebook sign on button click
*/
signInWithFacebook(): void {
  this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID);
  this.socialService.authState.subscribe((user:any) => {
    this.isAPILoading = true;
    var params = {
        'username':user.name,
        'first_name': user.firstName,
        'last_name': user.lastName,
        'email':user.email ? user.email : user.id + '@facebook.com',
        'photo':user.photoUrl,
        'profile_image':user.id + '.jpg',
        'social_info':JSON.stringify({"id":user.id,"type":"facebook"})
      }
      console.log(params);
      this.userService.register(params).subscribe(
      (result: any) => {
        console.log(result)
        this.isAPILoading = false;
        if(result.status == true) {
          localStorage.setItem('token',result.return_id);
          this.userService.refreshUser();
          this.snackBar.open(this.translateService.instant('Login Successful'), "", { duration: 2000 });
          // location.href = config.base_url;
          this.router.navigate(['/']);
        } else {
          this.snackBar.open(result.message, "", { duration: 2000 });
        }
      }
    )
  });
}

}
