<div class="nft__item1">

    <div style="color: black!important; font-weight: bold;"  *ngIf="item.sell_method == 2 && timeLeft() > 0 && item.for_sale" class="de_countdown bg-color-secondary text-white is-countdown">
        <countdown [config]="cdConfig"></countdown>
    </div>

    <div class="nft__item_wrap1">
        <a href="javascript:void(0)" (click)="viewAction()">
            <img [src]="mediaBase + '/images/item/thumb/' + item.thumb" class="lazy nft__item_preview" alt="">
        </a>
    </div>
    <div class="nft__item_info1">
        <a href="javascript:void(0)">
            <h3>{{item.name}}</h3>
        </a>

    </div>
</div>
