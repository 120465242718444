<div class="card-two" [ngStyle]="{'background-image': 'url(' + img + ')'}">
    <!-- <button class="newbtn btnsize3" style="position: absolute; top: -2px; margin-left: 80px">{{title}}</button>
    <div style="position: absolute; bottom: 50px; margin: 20px">
        <div class="left">
            <span class="cd-text1">{{name}}</span>
        </div>
        <div class="left">
            <span class="cd-text2">{{description}}</span>
        </div>
        <div class="left">
            <span class="cd-text3" style="cursor: pointer;">{{name}} <i class="fa fa-arrow-right" style="font-size: 22px; color:white"></i></span>
        </div>
    </div> -->

</div>