import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from 'src/environments/environment';
import { last, map } from 'rxjs/operators';

const url = environment.url;

@Injectable({
  providedIn: 'root'
})
export class ApService {

  constructor(
    private http: HttpClient,
  ) { }

  setApToken(token: string){
    if (token) return localStorage.setItem('ap_token', token);
  }

  getApToken(): string {
    const apToken = localStorage.getItem('ap_token');
    return apToken? apToken : '';
  }

  getApAdminToken(wallet: string): Observable<string> {

    var formData: any = new FormData();
    formData.append('username', 'superadmin');
    formData.append('password', 'ART$f9mmf75a+g');
    formData.append('device_type', '1');
    formData.append('device_token', 'e5b54edbceaaf0e8a7eae583b693303698ed6b9aefe4139828e8d0c89ca2f5ac');
    formData.append('wallet', wallet);

    console.log('ap user login');

    return this.http.post<any>(url.afiliate+'/api/login', formData)
    .pipe(map((response: any) => {
      const token: string = response.token;
      console.log('response',response);
      return token ? token : '';
    }))
  }

  registerUser(wallet: string, refer_id: string, user: any): Observable<any> {

    var formData: any = new FormData();
    formData.append('wallet', wallet);
    formData.append('refer_id', refer_id);
    formData.append('user', JSON.stringify(user));

    return this.http.post(url.afiliate+'/api/register', formData);
  }

  registerOrder(wallet: string, date: string, order_currency: string, 
                product_ids: string, product_prices: string): Observable<any> {

    var formData: any = new FormData();
    formData.append('wallet', wallet);
    formData.append('date', date);
    formData.append('order_currency', order_currency);
    formData.append('product_ids', product_ids);
    formData.append('product_prices', product_prices);

    const headers = { 'Auth-Token': this.getApToken() };

    return this.http.post<any>(url.afiliate+'/api/add_order', formData, {headers})
    .pipe(map((response: any) => {
      console.log('response',response);
      return 'order sent';
    }))
  }

  updateUser(wallet: string, first_name: string, last_name: string, 
                username: string, email: string): Observable<any> {

    var formData: any = new FormData();
    formData.append('wallet', wallet);
    formData.append('firstname', first_name);
    formData.append('lastname', last_name);
    formData.append('username', username);
    formData.append('email', email);

    const headers = { 'Auth-Token': this.getApToken() };

    return this.http.post<any>(url.afiliate+'/api/edit_user', formData, {headers})
    .pipe(map((response: any) => {
      console.log('user update response',response);
      return 'user updated';
    }))
  }

  userTransactions(wallet: string): Observable<any> {

    var formData: any = new FormData();
    formData.append('wallet', wallet);

    const headers = { 'Auth-Token': this.getApToken() };

    return this.http.post(url.afiliate+'/api/aff_trxs', formData, {headers});
  }

  withdrawalRequest(wallet: string): Observable<any> {

    var formData: any = new FormData();
    formData.append('wallet', wallet);

    const headers = { 'Auth-Token': this.getApToken() };

    return this.http.post(url.afiliate+'/api/withdrawal_request', formData, {headers});
  }

  withdrawalRequestHistory(wallet: string): Observable<any> {

    var formData: any = new FormData();
    formData.append('wallet', wallet);

    const headers = { 'Auth-Token': this.getApToken() };

    return this.http.post(url.afiliate+'/api/withdrawal_request_history', formData, {headers});
  }

  affiliateList(wallet: string): Observable<any> {

    var formData: any = new FormData();
    formData.append('wallet', wallet);

    const headers = { 'Auth-Token': this.getApToken() };

    return this.http.post(url.afiliate+'/api/refer_list', formData, {headers});
  }

}
